import React from 'react';
import {NavLink} from "react-router-dom";

class CustomInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            error: props.error
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            error: nextProps.error
        })
    }

    render() {

        let react = this;

        let error = "";
        if (react.state.error !== null) {
            error = " error";
        }

        return (
            <div className={"custom-input" + error}>
                <input
                    type={react.props.type}
                    placeholder={react.props.placeholder}
                    value={react.state.value}
                    onChange={(event) => {
                        react.props.onChange(event.target.value);
                    }}
                />
                {react.state.error !== null && (
                    <img src="/images/icons/error-icon.svg" alt="" className="custom-input-icon"/>
                )}
                <span className="custom-input-error">{react.state.error}</span>
            </div>
        )

    }

}

export default CustomInput;

import axios from "axios";
import {api_url} from "../variables";

export function getPoints(
    access_token,
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/point-management/points/managements', {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}


export function updatePoint(
    access_token,
    id,
    points
) {

    return new Promise((resolve, reject) => {

        axios.patch(api_url + '/api/backend/point-management/points/managements/' + id, {
            id,
            points
        }, {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            console.log(response);
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

import React from 'react';
import {NavLink} from "react-router-dom";

class CustomSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: props.value
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                open: false
            })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    changeOpen() {
        this.setState({
            open: !this.state.open
        })
    }

    select(element) {
        this.setState({
            open: false
        }, () => {
            this.props.onSelect(element);
        })
    }

    checkLabel() {

        if (this.state.value === null) {
            return this.props.placeholder;
        }

        if (this.props.is_object) {
            return this.state.value[this.props.object_label]
        }

        return this.state.value;

    }

    render() {

        let react = this;

        let classname_open = "";
        if (react.state.open) {
            classname_open = " open";
        }

        return (
            <div className="custom-select" ref={this.setWrapperRef}>
                <div className={"custom-select-top" + classname_open} onClick={react.changeOpen.bind(react)}>
                    <span className="custom-select-top-label">{react.checkLabel()}</span>
                    <div className="custom-select-top-arrow"/>
                </div>
                {react.state.open && (
                    <div className="custom-select-submenu">
                        {
                            react.props.elements.map(function (element) {
                                return (
                                    <span
                                        onClick={react.select.bind(react, element)}
                                        className="custom-select-submenu-element"
                                    >
                                        {react.props.is_object ? element[react.props.object_label] : element}
                                    </span>
                                )
                            })
                        }
                    </div>
                )}
            </div>
        )

    }

}

export default CustomSelect;

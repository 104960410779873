import React, { Fragment } from 'react';
import LeftMenu from "../components/LeftMenu";
import { connect } from "react-redux";
import { getUsers } from "../actions/userActions";
import UserModal from "../components/UserModal";
import moment from "moment";
import { Redirect } from "react-router";

class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      initial_loading: true,
      loading: false,
      users: [],
      page: 1,
      total: 0,
      per_page: 13,
      user_modal_open: false,
      user_modal_obj: null
    }
  }

  componentWillMount() {
    this.loadUsers();
  }

  async loadUsers() {
    let react = this;
    react.setState({
      loading: true
    })

    try {
      let response = await getUsers(react.props.access_token, react.state.page);
      console.log(response);

      react.setState({
        initial_loading: false,
        loading: false,
        total: response.meta.total,
        per_page: response.meta.per_page,
        users: response.data,
      })
    } catch (error) {
      console.log(error);
    }
  }

  closeUserModal() {
    this.setState({
      user_modal_open: false,
      user_modal_obj: null
    })
  }

  openUserModal(obj) {
    this.setState({
      user_modal_open: true,
      user_modal_obj: obj
    })
  }

  render() {
    let react = this;
    if (react.props.access_token === null) {
      return <Redirect to={"/login"} />
    }

    let end = false;
    let page_1 = 1;
    if (react.state.page > 1) {
      page_1 = react.state.per_page * (react.state.page - 1);
    }
    let page_2 = react.state.per_page * react.state.page;
    if (page_2 > react.state.total) {
      end = true;
      page_2 = react.state.total;
    }

    return (
      <Fragment>
        <UserModal
          open={react.state.user_modal_open}
          user={react.state.user_modal_obj}
          onClose={react.closeUserModal.bind(react)}
        />
        <LeftMenu />
        <div className="page-content">
          <div className="page-content-inner">
            {react.state.initial_loading ? (
              <div className="page-content-loader">
                <div className="loader">
                  <div className="inner one" />
                  <div className="inner two" />
                  <div className="inner three" />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="page-top row">
                  <h1 className="page-top-title">User Managment</h1>
                  <div className="page-top-right">
                    <div className="num-block row">
                      <div className="num-block-icon">
                        <img src="/images/people.svg" alt="" className="num-block-icon-image" />
                      </div>
                      <div className="num-block-right">
                        <span className="num-block-label">Total users</span>
                        <span className="num-block-value">{react.state.total}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="data-table-inner">
                  {
                    react.state.loading ? (
                      <div className="tasks-inner-elements-loader">
                        <div className="spinner" />
                      </div>
                    ) : (
                      <table className="data-table">
                        <tr>
                          <th>Full Name</th>
                          <th style={{ width: '340px' }}>Phone Number</th>
                          <th style={{ width: '340px' }}>Email Address</th>
                          <th style={{ width: '64px' }} />
                        </tr>
                        {
                          react.state.users.map(function (user) {
                            return (
                              <UsersElement
                                user={user}
                                onOpenUserModal={react.openUserModal.bind(react, user)}
                              />
                            )
                          })
                        }
                      </table>
                    )
                  }
                </div>
                <div className="data-table-bottom">
                  <div className="pagination row">
                    {react.state.total > react.state.per_page && (
                      <div className="pagination-left row">
                        <span className="pagination-label">Showing {page_1} to {page_2} from {react.state.total} entries</span>
                        <div className="pagination-area">
                          <button className="pagination-area-left" onClick={() => {
                            if (react.state.page !== 1) {
                              react.setState({
                                page: --react.state.page
                              }, () => {
                                react.loadUsers();
                              })
                            }
                          }} />
                          <input type="text" disabled value={react.state.page} className="pagination-area-input" />
                          <button className="pagination-area-right" onClick={() => {
                            if (!end) {
                              react.setState({
                                page: ++react.state.page
                              }, () => {
                                react.loadUsers();
                              })
                            }
                          }} />
                        </div>
                      </div>
                    )}
                    <span className="pagination-copyright">&copy; {moment().format("YYYY")} A Little Dose of Happy</span>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    )

  }

}

class UsersElement extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      user: nextProps.user
    })
  }

  render() {

    let react = this;
    let user = react.state.user;

    return (
      <tr>
        <td>
          <div className="data-table-profile row">
            <div style={{ backgroundImage: 'url(' + user.avatar + ')' }} className="data-table-profile-photo" />
            <span className="data-table-profile-name">{user.first_name} {user.last_name}</span>
          </div>
        </td>
        <td>
          <span className="data-table-text">{user.phone_number}</span>
        </td>
        <td>
          <span className="data-table-text">{user.email}</span>
        </td>
        <td className="padding-no">
          <button className="data-table-points" onClick={react.props.onOpenUserModal.bind(react)}>
            <span className="data-table-points-circle" />
            <span className="data-table-points-circle" />
            <span className="data-table-points-circle" />
          </button>
        </td>
      </tr>
    )

  }

}

export default connect(
  (state) => ({
    access_token: state.user.access_token,
  }),
  (dispatch) => ({}),
)(Users);

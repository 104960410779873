import axios from "axios";
import {api_url} from "../variables";

export function getTopCountries(
    access_token
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/dashboard/top-countries', {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

export function getUserAge(
    access_token
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/dashboard/user-age', {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}


export function getGraph(
    access_token,
    data,
    period
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/dashboard/graph?data=' + data + '&period=' + period, {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

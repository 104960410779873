
const initialState = {
    access_token: null,
};

export default function user(state = initialState, action) {

    switch (action.type) {
        case 'LOGOUT':
            return {
                ...state,
                access_token: null,
            };
        case 'SET_USER':
            return {
                ...state,
                access_token: action.access_token,
            };
        default:
            return state;
    }
}

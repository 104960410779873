export function validateEmail(email)  {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function add_value(data, label, value) {

    if (value !== null && value !== undefined) {

        data.append(label, value);

    }

    return data;

}

export function get_id(value) {

    if (value === null) {
        return null;
    }

    return value.id;

}

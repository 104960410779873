import React, {Fragment} from 'react';
import {connect} from "react-redux";
import CustomSelect from "./CustomSelect";
import Button from "./Button";
import {getTask, sendTasksExcel, taskCreate, taskUpdate} from "../actions/taskActions";
import CustomInput from "./CustomInput";
import CustomLargeInput from "./CustomLargeInput";

class TaskModal extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            open: props.open,
            submit_loading: false,
            file: null,
            error: null
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            open: nextProps.open,
        })

    }

    submit() {

        let react = this;
        react.setState({
            submit_loading: true
        })

        sendTasksExcel(react.props.access_token, react.state.file).then(function(response) {

            react.setState({
                submit_loading: false
            }, () => {
                react.props.onUpdate();
            })

        }).catch(function (error) {

            react.setState({
                submit_loading: false,
                error: "An error occurred while processing the file"
            })

        })

    }

    onFileChange(event) {

        console.log(event.target.files);
        if (event.target.files.length > 0) {
            this.setState({
                file: event.target.files[0],
                error: null
            })
        } else {
            this.setState({
                file: null,
                error: null
            })
        }

    }

    openFileDialog() {
        this.file.click();
    }

    render() {

        let react = this;

        if (react.state.open) {
            return (
                <div className="import-modal">
                    <div className="import-modal-fade" onClick={react.props.onClose.bind(react)}/>
                    <div className="import-modal-window">
                        <div className="import-modal-window-top row">
                            <span className="import-modal-window-title">Import Excel</span>
                            <button className="import-modal-window-close" onClick={react.props.onClose.bind(react)}>
                                <img src="/images/icons/modal-close.svg" alt=""/>
                            </button>
                        </div>
                        <input accept={".xlsx"} type="file" ref={(input) => {this.file = input}} style={{display: "none"}} onChange={this.onFileChange.bind(this)}/>
                        <div className="import-modal-window-content" onClick={() => {
                            react.openFileDialog();
                        }}>
                            {react.state.file !== null && (
                                <Fragment>
                                    <span className="import-modal-window-content-label">{react.state.file.name}</span>
                                    <span className="import-modal-window-content-error">{react.state.error}</span>
                                </Fragment>
                            )}
                        </div>
                        <div className="import-modal-window-bottom">
                            <div className="import-modal-window-buttons">
                                <a href="/" className="import-modal-window-cancel-link" onClick={(event) => {
                                    event.preventDefault();
                                    react.props.onClose();
                                }}>Cancel</a>
                                <Button
                                    label={"Import"}
                                    onClick={react.submit.bind(react)}
                                    loading={react.state.submit_loading}
                                    classname={"import-modal-window-submit"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return <div/>

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(TaskModal);

import React, {Fragment} from 'react';
import {connect} from "react-redux";

class UserModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            user: props.user,
            loading: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            user: nextProps.user
        })

    }


    render() {

        let react = this;
        let user = react.state.user;

        console.log(user);

        if (react.state.open) {
            return (
                <div className="user-modal">
                    <div className="user-modal-fade" onClick={react.props.onClose.bind(react)}/>
                    <div className="user-modal-window">
                        <div className="user-modal-window-top row">
                            <span className="user-modal-window-title">User Profile</span>
                            <button className="user-modal-window-close" onClick={react.props.onClose.bind(react)}>
                                <img src="/images/icons/modal-close.svg" alt=""/>
                            </button>
                        </div>
                        {react.state.loading ? (
                            <div className="user-modal-window-loader">
                                <div className="spinner"/>
                            </div>
                        ) : (
                            <Fragment>
                                <div className="user-modal-window-info">
                                    <div style={{backgroundImage: 'url(' + user.avatar + ')'}} className="user-modal-window-info-image"/>
                                    <div className="user-modal-window-info-center">
                                        <span className="user-modal-window-info-name">{user.first_name} {user.last_name}</span>
                                        <span className="user-modal-window-info-position">{user.status} </span>
                                        <div className="user-modal-window-info-elements">
                                            <div className="user-modal-window-info-element row">
                                                <img src="images/icons/mail-outline.svg" className="user-modal-window-info-element-icon"/>
                                                <a href="#" className="user-modal-window-info-element-label">{user.email}</a>
                                            </div>
                                            <div className="user-modal-window-info-element row">
                                                <img src="images/icons/call-outline.svg" className="user-modal-window-info-element-icon"/>
                                                <a href="#" className="user-modal-window-info-element-label">{user.phone_number}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-modal-window-info-icons">
                                        <div className="user-modal-window-info-icon">
                                            <img src="images/icons/icon-people.svg" alt="" className="user-modal-window-info-icon-image"/>
                                                <div className="user-modal-window-info-icon-right">
                                                    <span className="user-modal-window-info-icon-label">Posts</span>
                                                    <span className="user-modal-window-info-icon-value">{user.posts?.length || 0}</span>
                                                </div>
                                        </div>
                                        <div className="user-modal-window-info-icon">
                                            <img src="images/icons/icon-money.svg" alt="" className="user-modal-window-info-icon-image"/>
                                            <div className="user-modal-window-info-icon-right">
                                                <span className="user-modal-window-info-icon-label">Survey level</span>
                                                <span className="user-modal-window-info-icon-value">{user.survey_level}</span>
                                            </div>
                                        </div>
                                        <div className="user-modal-window-info-icon">
                                            <img src="images/icons/icon-points.svg" alt="" className="user-modal-window-info-icon-image"/>
                                            <div className="user-modal-window-info-icon-right">
                                                <span className="user-modal-window-info-icon-label">Points</span>
                                                <span className="user-modal-window-info-icon-value">{user.total_points}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {user.posts?.length > 0 && (
                                  <div className="user-modal-window-posts">
                                      <span className="user-modal-window-posts-title">User Posts</span>
                                      <div className="user-modal-window-posts-elements">
                                          {
                                              user.posts.map(function (post) {
                                                  return (
                                                      <div className="user-modal-window-post">
                                                          {post.image !== null && (
                                                              <img src={post.image.url} alt="" className="user-modal-window-post-image"/>
                                                          )}
                                                          <div className="user-modal-window-post-right">
                                                              <div className="user-modal-window-post-top">
                                                                  <div className="user-modal-window-post-top-left">
                                                                      <span className="user-modal-window-post-title">{post.task.title}</span>
                                                                      <span className="user-modal-window-post-shortcode">({post.task.short_name})</span>
                                                                      <img src="images/icons/point-star.svg" alt="" className="user-modal-window-post-icon"/>
                                                                      <span className="user-modal-window-post-label">20</span>
                                                                  </div>
                                                                  <div className="user-modal-window-post-date">{post.created_at}</div>
                                                              </div>
                                                              <div className="user-modal-window-post-description">
                                                                  <p>{post.description}</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              })
                                          }
                                      </div>
                                  </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                </div>
            )
        }

        return <div/>

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(UserModal);

import React from 'react';
import {NavLink} from "react-router-dom";
import {getTopCountries, getUserAge} from "../actions/statisticActions";
import {connect} from "react-redux";

class UserAge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true
        }
    }

    async componentWillMount() {

        let react = this;
        react.setState({
            loading: true
        })

        try {

            let response = await getUserAge(react.props.access_token);
            console.log(response);

            react.setState({
                data: response.date[0],
                loading: false
            })

        } catch (error) {
            console.log(error);
        }

    }

    getElement(label, percent) {

        return (
            <div className="statistic-element">
                <span className="statistic-element-label">{label}</span>
                <div className="statistic-element-line">
                    <div
                        className="statistic-element-line-color"
                        style={{width: percent + '%'}}
                    />
                </div>
                <span className="statistic-element-percent">{percent}%</span>
            </div>
        )

    }

    getContent() {

        let react = this;

        if (react.state.loading || react.state.data === null) {
            return (
                <div className="statistic-loader">
                    <div className="spinner"/>
                </div>
            )
        }

        let data = react.state.data;


        return (
            <div className="statistic-elements">
                {react.getElement('16-20', data['16-20'])}
                {react.getElement('20-30', data['20-30'])}
                {react.getElement('30-40', data['30-40'])}
                {react.getElement('40-50', data['40-50'])}
                {react.getElement('60+', data['60+'])}
            </div>
        )

    }

    render() {

        let react = this;

        return (
            <div className="statistic">
                <span className="statistic-title">User Age</span>
                {react.getContent()}
            </div>
        )

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(UserAge);

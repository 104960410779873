import React, {Fragment} from 'react';
import {connect} from "react-redux";
import CustomSelect from "./CustomSelect";
import Button from "./Button";
import {taskUpdate, taskDelete} from "../actions/taskActions";
import CustomInput from "./CustomInput";
import CustomLargeInput from "./CustomLargeInput";
import {get_id} from "../helpers";

class TaskModal extends React.Component {

    constructor(props) {
        super(props);

        let id = "";
        let type = "random";
        let task_title = "";
        let task_description = "";
        let task_idea_link = "";
        let link_label = "";
        let idea_description = "";

        let important_person_relation = null;
        if (props.important_person_relations.length > 0) {
            important_person_relation = props.important_person_relations[0];
        }

        if (props.task !== null) {
            id = props.task.id;
            type = props.task.type;
            task_title = props.task.title;
            task_description = props.task.description;
            task_idea_link = props.task.ideas_url;
            link_label = props.task.ideas_url_label;
            idea_description = props.task.ideas;

            if (
                props.task.important_person_relation_id !== null &&
                props.task.important_person_relation_id !== undefined
            ) {

                props.important_person_relations.forEach(function (obj) {
                    if (obj.id === props.task.important_person_relation_id) {
                        important_person_relation = obj;
                    }
                })

            }

        }

        let type_of_task = props.type_of_task;
        if (type_of_task === "Bonus Act Of Kindness") {
            type_of_task = "Act Of Kindness";
        }

        this.state = {
            open: props.open,

            loading: false,
            submit_loading: false,
            delete_loading: false,

            important_person_relations: props.important_person_relations,
            important_person_relation,

            id,
            type_of_task: type_of_task,
            type,
            task_title,
            task_description,
            task_idea_link,
            link_label,
            idea_description,

            task_title_error: null,
            task_description_error: null,
            task_idea_link_error: null,
            link_label_error: null,
            idea_description_error: null

        }
    }

    componentWillReceiveProps(nextProps) {

        let id = "";
        let task_title = "";
        let task_description = "";
        let task_idea_link = "";
        let link_label = "";
        let idea_description = "";
        let type = "random";

        let important_person_relation = null;
        if (nextProps.important_person_relations.length > 0) {
            important_person_relation = nextProps.important_person_relations[0];
        }

        if (nextProps.task !== null) {
            id = nextProps.task.id;
            type = nextProps.task.type;
            task_title = nextProps.task.title;
            task_description = nextProps.task.description;
            task_idea_link = nextProps.task.ideas_url;
            link_label = nextProps.task.ideas_url_label;
            idea_description = nextProps.task.ideas;

            if (
                nextProps.task.important_person_relation_id !== null &&
                nextProps.task.important_person_relation_id !== undefined
            ) {

                nextProps.important_person_relations.forEach(function (obj) {
                    if (obj.id === nextProps.task.important_person_relation_id) {
                        important_person_relation = obj;
                    }
                })

            }

        }

        let type_of_task = nextProps.type_of_task;
        if (type_of_task === "Bonus Act Of Kindness") {
            type_of_task = "Act Of Kindness";
        }

        this.setState({
            open: nextProps.open,

            important_person_relations: nextProps.important_person_relations,
            important_person_relation,

            id,
            type,
            type_of_task: type_of_task,
            task_title,
            task_description,
            task_idea_link,
            link_label,
            idea_description,

            task_title_error: null,
            task_description_error: null,
            task_idea_link_error: null,
            link_label_error: null,
            idea_description_error: null

        })

    }

    async submit() {

        let react = this;

        react.setState({
            submit_loading: true
        })

        await taskUpdate(
            react.props.access_token,
            react.state.id,
            react.state.type_of_task,
            react.state.type,
            get_id(react.state.important_person_relation),
            react.state.task_title,
            react.state.task_description,
            react.state.task_idea_link,
            react.state.link_label,
            react.state.idea_description
        ).then(function (response) {
            react.setState({
                submit_loading: false
            }, () => {
                react.props.onUpdate(react.state.type_of_task);
            })
        }).catch(function(error) {

            console.log(error);

            let task_title_error = null;
            let task_description_error = null;
            let task_idea_link_error = null;
            let link_label_error = null;
            let idea_description_error = null;

            let obj = error.response.data.errors;

            if (obj.title !== undefined) {
                task_title_error = obj.title;
            }

            if (obj.description !== undefined) {
                task_description_error = obj.description;
            }

            if (obj.ideas !== undefined) {
                idea_description_error = obj.ideas;
            }

            if (obj.ideas_url !== undefined) {
                task_idea_link_error = obj.ideas_url;
            }

            if (obj.ideas_url_label !== undefined) {
                link_label_error = obj.ideas_url_label;
            }

            react.setState({
                submit_loading: false,
                task_title_error,
                task_description_error,
                idea_description_error,
                task_idea_link_error,
                link_label_error
            });

        });

    }

    async delete() {
        let react = this;

        react.setState({
            delete_loading: true
        })

        await taskDelete(
            react.props.access_token,
            react.state.id,
            react.state.type_of_task,
        ).then(function (response) {
            react.setState({
                delete_loading: false
            }, () => {
                react.props.onUpdate(react.state.type_of_task);
            })
        }).catch(function(error) {
            console.log(error);
            react.setState({
                delete_loading: false,
            });
        });
    }

    render() {

        let react = this;

        if (react.state.open) {
            return (
                <div className="task-modal">
                    <div className="task-modal-fade" onClick={react.props.onClose.bind(react)}/>
                    <div className="task-modal-window">
                        <div className="user-modal-window-top row">
                            <span className="user-modal-window-title">Edit Task</span>
                            <button className="user-modal-window-close" onClick={react.props.onClose.bind(react)}>
                                <img src="/images/icons/modal-close.svg" alt=""/>
                            </button>
                        </div>
                        {react.state.loading ? (
                            <div className="task-modal-window-loader">
                                <div className="spinner"/>
                            </div>
                        ) : (
                            <Fragment>
                                <div className="task-modal-window-form">
                                    <div className="task-modal-window-form-col">
                                        <div className="task-modal-window-input">
                                            <CustomInput
                                                type={"text"}
                                                placeholder={"Task title"}
                                                error={react.state.task_title_error}
                                                value={react.state.task_title}
                                                onChange={(value) => {
                                                    react.setState({
                                                        task_title: value,
                                                        task_title_error: null
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="task-modal-window-select">
                                            <CustomSelect
                                                placeholder={"Type of Task"}
                                                value={react.state.type_of_task}
                                                elements={['Self Care', 'Act Of Kindness', 'Relationship Maintenance']}
                                                onSelect={(element) => {
                                                    react.setState({
                                                        type_of_task: element
                                                    })
                                                }}
                                            />
                                        </div>
                                        {(react.state.type_of_task === "Act Of Kindness") && (
                                            <div className="task-modal-window-select" style={{marginTop: 29}}>
                                                <CustomSelect
                                                    placeholder={"Type"}
                                                    value={react.state.type}
                                                    elements={['random', 'bonus']}
                                                    onSelect={(element) => {
                                                        react.setState({
                                                            type: element
                                                        })
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {react.state.type_of_task === "Relationship Maintenance" && (
                                            <div className="task-modal-window-select" style={{marginTop: 29}}>
                                                <CustomSelect
                                                    placeholder={"Important person"}
                                                    value={react.state.important_person_relation}
                                                    is_object={true}
                                                    object_label={'title'}
                                                    elements={react.state.important_person_relations}
                                                    onSelect={(element) => {
                                                        react.setState({
                                                            important_person_relation: element
                                                        })
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <div className="task-modal-window-large-input" style={{marginTop: 29}}>
                                            <CustomLargeInput
                                                type={"text"}
                                                placeholder={"Task description"}
                                                error={react.state.task_description_error}
                                                value={react.state.task_description}
                                                onChange={(value) => {
                                                    react.setState({
                                                        task_description: value,
                                                        task_description_error: null
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="task-modal-window-form-col">
                                        <div className="task-modal-window-input">
                                            <CustomInput
                                                type={"text"}
                                                placeholder={"Task idea link"}
                                                error={react.state.task_idea_link_error}
                                                value={react.state.task_idea_link}
                                                onChange={(value) => {
                                                    react.setState({
                                                        task_idea_link: value,
                                                        task_idea_link_error: null
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="task-modal-window-input">
                                            <CustomInput
                                                type={"text"}
                                                placeholder={"Link label"}
                                                error={react.state.link_label_error}
                                                value={react.state.link_label}
                                                onChange={(value) => {
                                                    react.setState({
                                                        link_label: value,
                                                        link_label_error: null
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="task-modal-window-large-input">
                                            <CustomLargeInput
                                                type={"text"}
                                                placeholder={"Task Ideas Description"}
                                                error={react.state.idea_description_error}
                                                value={react.state.idea_description}
                                                onChange={(value) => {
                                                    react.setState({
                                                        idea_description: value,
                                                        idea_description_error: null
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="task-modal-window-bottom">
                                    <div className="task-modal-window-buttons">
                                        <a href="/" className="task-modal-window-cancel-link" onClick={(event) => {
                                            event.preventDefault();
                                            react.props.onClose();
                                        }}>Cancel</a>
                                        {(
                                          react.state.type_of_task === "Self Care" ||
                                          react.state.type_of_task === "Act Of Kindness" ||
                                          react.state.type_of_task === "Relationship Maintenance"
                                        ) && (
                                            <Button
                                                label={"Delete"}
                                                onClick={react.delete.bind(react)}
                                                loading={react.state.delete_loading}
                                                classname={"task-modal-window-delete delete-button"}
                                            />
                                        )}
                                        <Button
                                            label={"Save"}
                                            onClick={react.submit.bind(react)}
                                            loading={react.state.submit_loading}
                                            classname={"task-modal-window-submit"}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            )
        }

        return <div/>

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(TaskModal);

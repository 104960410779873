import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router";
import LeftMenu from "../components/LeftMenu";
import Slider from 'react-input-slider';
import {getPoints, updatePoint} from "../actions/pointActions";

class Points extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            points_1: [],
            points_2: [],
            initial_loading: true,
        }
    }

    async componentWillMount() {

        let react = this;

        let response = await getPoints(react.props.access_token);
        console.log(response);

        react.setState({
            points_1: response.data.slice(0,10),
            points_2: response.data.slice(10,20),
            initial_loading: false,
        })

    }

    render() {

        let react = this;
        if (react.props.access_token === null) {
            return <Redirect to={"/login"}/>
        }

        return (
            <Fragment>
                <LeftMenu/>
                <div className="page-content">
                    <div className="page-content-inner">
                        {react.state.initial_loading ? (
                            <div className="page-content-loader">
                                <div className="loader">
                                    <div className="inner one"/>
                                    <div className="inner two"/>
                                    <div className="inner three"/>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <div className="page-top row">
                                    <h1 className="page-top-title">Points Management</h1>
                                </div>
                                <div className="points row">
                                    <div className="points-block">
                                        {
                                            react.state.points_1.map(function (point, index) {
                                                return (
                                                    <div className="points-block-element">
                                                        <div className="points-block-element-top">
                                                            <span className="points-block-element-label">{point.alias}</span>
                                                            <span className="points-block-element-value">{point.points}</span>
                                                        </div>
                                                        <div className="points-block-element-slider">
                                                            <Slider
                                                                axis="x"
                                                                xstep={1}
                                                                xmin={0}
                                                                xmax={100}
                                                                x={parseInt(point.points)}
                                                                onChange={(coords) => {

                                                                    let points = react.state.points_1;
                                                                    points[index].points = coords.x;
                                                                    react.setState({
                                                                        points_1: points
                                                                    });

                                                                }}
                                                                onDragEnd={async () => {
                                                                    await updatePoint(react.props.access_token, point.id, point.points);
                                                                }}
                                                                styles={{
                                                                    track: {
                                                                        height: 2,
                                                                        backgroundColor: 'rgba(8, 147, 218, 0.12)',
                                                                        borderRadius: 4,
                                                                        width: '100%',
                                                                    },
                                                                    active: {
                                                                        backgroundColor: '#0893DA'
                                                                    },
                                                                    thumb: {
                                                                        width: 16,
                                                                        height: 16,
                                                                        borderRadius: 16,
                                                                        backgroundColor: '#0893DA'
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="points-block">
                                        {
                                            react.state.points_2.map(function (point, index) {
                                                return (
                                                    <div className="points-block-element">
                                                        <div className="points-block-element-top">
                                                            <span className="points-block-element-label">{point.alias}</span>
                                                            <span className="points-block-element-value">{point.points}</span>
                                                        </div>
                                                        <div className="points-block-element-slider">
                                                            <Slider
                                                                axis="x"
                                                                xstep={1}
                                                                xmin={0}
                                                                xmax={100}
                                                                x={parseInt(point.points)}
                                                                onChange={(coords) => {

                                                                    let points = react.state.points_2;
                                                                    points[index].points = coords.x;
                                                                    react.setState({
                                                                        points_2: points
                                                                    });

                                                                }}
                                                                onDragEnd={async () => {
                                                                    await updatePoint(react.props.access_token, point.id, point.points);
                                                                }}
                                                                styles={{
                                                                    track: {
                                                                        height: 2,
                                                                        backgroundColor: 'rgba(8, 147, 218, 0.12)',
                                                                        borderRadius: 4,
                                                                        width: '100%',
                                                                    },
                                                                    active: {
                                                                        backgroundColor: '#0893DA'
                                                                    },
                                                                    thumb: {
                                                                        width: 16,
                                                                        height: 16,
                                                                        borderRadius: 16,
                                                                        backgroundColor: '#0893DA'
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        )

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(Points);


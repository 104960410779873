import React from 'react';
import { createBrowserHistory } from 'history'
import {ConnectedRouter, routerMiddleware} from "react-router-redux";
import {createLogger} from "redux-logger";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import reducer from './reducers';
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Tasks from "./pages/Tasks";
import Points from "./pages/Points";
import Cookies from 'js-cookie'
import {getTopCountries} from "./actions/statisticActions";

const history = createBrowserHistory()
const middleware = routerMiddleware(history);

const logger = createLogger({});
const store = createStore(reducer, applyMiddleware(middleware, thunk, logger));


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            access_token: props.access_token
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            access_token: nextProps.access_token
        })
    }

    async componentDidMount() {

        let react = this;

        let access_token = Cookies.get('access_token', {path: '/'});

        if (access_token !== undefined) {

            try {

                await getTopCountries(access_token);

                store.dispatch({
                    type: "SET_USER",
                    access_token: access_token,
                });
                react.setState({
                    loading: false
                })

            } catch {

                react.setState({
                    loading: false
                })

            }

        } else {

            react.setState({
                loading: false
            })

        }


    }

    render() {

        let react = this;

        if (react.state.loading) {
            return (
                <div>
                    <div className="loader">
                        <div className="inner one"/>
                        <div className="inner two"/>
                        <div className="inner three"/>
                    </div>
                </div>
            )
        }

        return (
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path="/" component={Dashboard}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/users" component={Users}/>
                    <Route exact path="/tasks" component={Tasks}/>
                    <Route exact path="/points" component={Points}/>
                </Switch>
              </ConnectedRouter>
            </Provider>
        )

  }

}

export default App;


import axios from "axios";
import {api_url} from "../variables";
import {add_value} from "../helpers";

export function getTasks(
    access_token,
    type,
    page
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/task-management/tasks?task=' + type + '&page=' + page, {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

export function getTasksTotal(
    access_token,
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/task-management/tasks/total', {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

export function getTasksRelations(
    access_token,
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/task-management/relations', {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}


export function taskCreate(
    access_token,
    task_type,
    type,
    important_person_relation,
    task_title,
    task_description,
    task_idea_link,
    link_label,
    idea_description
) {

    return new Promise((resolve, reject) => {

        let data = new FormData();

        data = add_value(data, 'task', task_type);
        data = add_value(data, 'type', type);
        data = add_value(data, 'important_person_relation_id', important_person_relation);
        data = add_value(data, 'title', task_title);
        data = add_value(data, 'description', task_description);
        data = add_value(data, 'ideas', idea_description);
        data = add_value(data, 'ideas_url', task_idea_link);
        data = add_value(data, 'ideas_url_label', link_label);

        axios.post(api_url + '/api/backend/task-management/tasks', data, {
            headers: {
                'accept': 'application/json',
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

export function taskUpdate(
    access_token,
    id,
    task_type,
    type,
    important_person_relation,
    task_title,
    task_description,
    task_idea_link,
    link_label,
    idea_description
) {

    return new Promise((resolve, reject) => {

        let data = new FormData();

        data = add_value(data, 'id', id);
        data = add_value(data, 'task', task_type);
        data = add_value(data, 'type', type);
        data = add_value(data, 'important_person_relation_id', important_person_relation);
        data = add_value(data, 'title', task_title);
        data = add_value(data, 'description', task_description);
        data = add_value(data, 'ideas', idea_description);
        data = add_value(data, 'ideas_url', task_idea_link);
        data = add_value(data, 'ideas_url_label', link_label);

        axios.post(api_url + '/api/backend/task-management/tasks/' + id, data, {
            headers: {
                'accept': 'application/json',
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            console.log(error);
            reject(error);
        })

    })

}

export function taskDelete( 
  access_token,
  id,
  task_type, 
) {
  return new Promise((resolve, reject) => {
    let data = new FormData();

    data = add_value(data, 'id', id);
    data = add_value(data, 'task', task_type);

    axios.post(api_url + '/api/backend/task-management/tasks/delete', data, {
      headers: {
        "Accept": "application/json",
        "Authorization": 'Bearer ' + access_token,
      }
    })
    .then(function (response) {
        resolve(response.data);
    }).catch(function (error) {
        console.log(error);
        reject(error);
    })
  })
}

export function getTasksExcel(
    access_token,
    task_type,
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/task-management/tasks/excel?tasks=' + task_type, {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}


export function sendTasksExcel(
    access_token,
    file
) {

    return new Promise((resolve, reject) => {

        let data = new FormData();
        data.append("file", file);

        axios.post(api_url + '/api/backend/task-management/tasks/excel', data ,{
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            console.log(response);
            resolve(response.data);
        }).catch(function (error) {
            console.log(error);
            reject(error);
        })

    })

}

import React from 'react';
import {NavLink} from "react-router-dom";
import {getTopCountries} from "../actions/statisticActions";
import {connect} from "react-redux";

class TopCountries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            loading: true
        }
    }

    async componentWillMount() {

        let react = this;
        react.setState({
            loading: true
        })

        try {

            let response = await getTopCountries(react.props.access_token);
            console.log(response);

            react.setState({
                countries: response.data.slice(0, 5),
                loading: false
            })

        } catch (error) {
            console.log(error);
        }

    }

    getContent() {

        let react = this;

        if (react.state.loading) {
            return (
                <div className="statistic-loader">
                    <div className="spinner"/>
                </div>
            )
        }

        return (
            <div className="statistic-elements">
                {
                    react.state.countries.map(function(country) {
                        return (
                            <div className="statistic-element">
                                <span className="statistic-element-label">{country.title}</span>
                                <div className="statistic-element-line">
                                    <div
                                        className="statistic-element-line-color"
                                        style={{width: country.percent + '%'}}
                                    />
                                </div>
                                <span className="statistic-element-percent">{country.percent}%</span>
                            </div>
                        )
                    })
                }
            </div>
        )

    }

    render() {

        let react = this;

        return (
            <div className="statistic">
                <span className="statistic-title">Top 5 Countries</span>
                {react.getContent()}
            </div>
        )

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(TopCountries);

import React from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {userLogout, userSet} from "../actions/userActions";

class LeftMenu extends React.Component {

    render() {

        let react = this;

        return (
            <div className="aside-menu">
                <NavLink to={'/'} exact={true} className="aside-menu-logo">
                    <img src="images/logo.png" alt="" className="aside-menu-logo-icon"/>
                </NavLink>
                <div className="aside-menu-ul">
                    <NavLink to="/" exact={true} className="aside-menu-ul-element">
                        <img className="aside-menu-ul-element-icon no-active" src="images/menu/home.svg" alt=""/>
                        <img className="aside-menu-ul-element-icon active" src="images/menu/home-active.svg" alt=""/>
                        <span className="aside-menu-ul-element-label">Dashboard</span>
                    </NavLink>
                    <NavLink to="/users" exact={true} className="aside-menu-ul-element">
                        <img className="aside-menu-ul-element-icon no-active" src="images/menu/people.svg" alt=""/>
                        <img className="aside-menu-ul-element-icon active" src="images/menu/people-active.svg" alt=""/>
                        <span className="aside-menu-ul-element-label">Users</span>
                    </NavLink>
                    <NavLink to="/tasks" exact={true} className="aside-menu-ul-element">
                        <img className="aside-menu-ul-element-icon no-active" src="images/menu/checkmark.svg" alt=""/>
                        <img className="aside-menu-ul-element-icon active" src="images/menu/checkmark-active.svg" alt=""/>
                        <span className="aside-menu-ul-element-label">Tasks</span>
                    </NavLink>
                    <NavLink to="/points" exact={true} className="aside-menu-ul-element">
                        <img className="aside-menu-ul-element-icon no-active" src="images/menu/star.svg" alt=""/>
                        <img className="aside-menu-ul-element-icon active" src="images/menu/star-active.svg" alt=""/>
                        <span className="aside-menu-ul-element-label">Points</span>
                    </NavLink>
                </div>
                <div className="aside-menu-bottom">
                    <button className="aside-menu-logout" onClick={() => {
                        react.props.onUserLogout();
                    }}>
                        <img className="aside-menu-logout-icon" src="images/menu/logout.svg" alt=""/>
                        <span className="aside-menu-logout-label">Log Out</span>
                    </button>
                </div>
            </div>
        )

    }

}

export default connect(
    (state) => ({}),
    (dispatch) => ({
        onUserLogout: () => {
            dispatch(userLogout());
        },
    }),
)(LeftMenu);

import React from 'react';
import {NavLink} from "react-router-dom";

class Button extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading
        })
    }

    render() {

        let react = this;

        let classname_loading = "";
        if (react.state.loading) {
            classname_loading = " loading";
        }

        return (
            <button className={react.props.classname + " blue-button" + classname_loading} onClick={react.props.onClick.bind(react)}>
                {react.state.loading && (
                    <span className="blue-button-spinner">
                        <span className="spinner"/>
                    </span>
                )}
                <span className="blue-button-label">{react.props.label}</span>
            </button>
        )

    }

}

export default Button;

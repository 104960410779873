import React from 'react';
import Button from "../components/Button";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {userLogin, userSet} from "../actions/userActions";
import CustomInput from "../components/CustomInput";
import {validateEmail} from "../helpers";


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: false,

            email: '',
            password: '',

            email_error: null,
            password_error: null

        }
    }

    submit() {

        let react = this;

        let error = false;
        let email_error = null;
        let password_error = null;

        if (!validateEmail(react.state.email)) {
            error = true;
            email_error = "Invalid email";
        }

        if (react.state.password.trim().length === 0) {
            error = true;
            password_error = "This field is empty";
        }

        if (error) {

            react.setState({
                email_error,
                password_error
            })
            return;

        }


        react.setState({
            loading: true
        })

        userLogin(react.state.email, react.state.password).then(function(response) {
            console.log(response);
            react.props.onUserSet(response.access_token);
            react.setState({
                redirect: true
            })
        }).catch(function (error) {
            react.setState({
                email_error: 'Invalid email or password',
                loading: false
            })
        })

    }

    render() {

        let react = this;

        if (react.props.access_token !== null || react.state.redirect) {
            return <Redirect to={"/"}/>
        }

        return (
            <div className={"login-page"}>
                <a href="/" className="login-page-logo">
                    <img src="/images/logo.png" alt="A little dose of happy" className="login-page-logo-image"/>
                </a>
                <span className="login-page-label">
                    <span className="blue">a</span>
                    <span className="blue">l</span>ittle
                    <span className="blue">d</span>ose
                    <span className="blue">o</span>f
                    <span className="blue">happy</span>
                </span>
                <div className="login-page-inner">
                    <span className="login-page-inner-title">Welcome Back</span>
                    <span className="login-page-inner-sub-title">Log In to manage the app</span>
                    <div className="login-page-form">
                        <div className="login-page-form-input">
                            <CustomInput
                                type={"email"}
                                placeholder={"Email"}
                                error={react.state.email_error}
                                value={react.state.email}
                                onChange={(value) => {
                                    react.setState({
                                        email: value,
                                        email_error: null
                                    })
                                }}
                            />
                        </div>
                        <div className="login-page-form-input">
                            <CustomInput
                                type={"password"}
                                placeholder={"Password"}
                                error={react.state.password_error}
                                value={react.state.password}
                                onChange={(value) => {
                                    react.setState({
                                        password: value,
                                        password_error: null
                                    })
                                }}
                            />
                        </div>
                        <Button
                            label={"Log in"}
                            onClick={react.submit.bind(react)}
                            loading={react.state.loading}
                            classname={"login-page-form-submit"}
                        />
                    </div>
                </div>
                <span className="login-page-copyright">&copy; 2020 A Little Dose of Happy</span>
            </div>
        )

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({
        onUserSet: (access_token) => {
            dispatch(userSet(access_token));
        },
    }),
)(Login);


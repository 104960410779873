import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router";
import LeftMenu from "../components/LeftMenu";
import moment from "moment";
import TopCountries from "../components/TopCountries";
import UserAge from "../components/UserAge";
import CustomSelect from "../components/CustomSelect";
import DashboardGraph from "../components/DashboardGraph";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        let react = this;
        if (react.props.access_token === null) {
            return <Redirect to={"/login"}/>
        }

        return (
            <Fragment>
                <LeftMenu/>
                <div className="page-content">
                    <div className="page-content-inner">
                        <div className="page-top row">
                            <h1 className="page-top-title">Dashboard</h1>
                            <div className="page-top-right">
                                <span className="page-top-date">{moment().format("DD MMM, dddd")}</span>
                            </div>
                        </div>
                        <div className="dashboard">
                            <div className="row">
                                <TopCountries/>
                                <UserAge/>
                            </div>
                            <DashboardGraph/>
                        </div>
                        <div className="data-table-bottom">
                            <div className="pagination row">
                                <span className="pagination-copyright">&copy; {moment().format("YYYY")} A Little Dose of Happy</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(Dashboard);


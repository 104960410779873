import React from 'react';
import {getGraph} from "../actions/statisticActions";
import {connect} from "react-redux";
import CustomSelect from "./CustomSelect";
import {
    XAxis, CartesianGrid, Tooltip, LineChart, Line, ResponsiveContainer
} from 'recharts';

class DashboardGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            total_users: 0,
            loading: true,

            data: 'User Activity',
            period: 'Last Week',

            content: [],

            elements: []

        }
    }

    async componentWillMount() {
        this.loadData();
    }

    async loadData() {

        let react = this;
        react.setState({
            loading: true
        })

        try {

            let response = await getGraph(react.props.access_token, react.state.data, react.state.period);
            console.log(response);

            react.setState({
                loading: false,
                elements: response.data,
                total_users: response.total_users,
            })

        } catch (error) {
            console.log(error);
        }

    }

    render() {

        let react = this;

        return (
            <div className="dashboard-main">
                <div className="dashboard-main-top row">
                    <div className="dashboard-main-top-left">
                        <div className="dashboard-main-select">
                            <CustomSelect
                                placeholder={""}
                                value={react.state.data}
                                elements={['User Activity', 'Registrations', 'Posts']}
                                onSelect={(element) => {
                                    react.setState({
                                        data: element
                                    }, () => {
                                        react.loadData();
                                    })
                                }}
                            />
                        </div>
                        <div className="dashboard-main-select">
                            <CustomSelect
                                placeholder={""}
                                value={react.state.period}
                                elements={['Last Week', 'Last Year', 'Last Month']}
                                onSelect={(element) => {
                                    react.setState({
                                        period: element
                                    }, () => {
                                        react.loadData();
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="dashboard-main-top-right">
                        <div className="num-block row">
                            <div className="num-block-icon">
                                <img src="/images/people.svg" alt="" className="num-block-icon-image"/>
                            </div>
                            <div className="num-block-right">
                                <span className="num-block-label">Total users</span>
                                <span className="num-block-value">{react.state.total_users}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-main-content">
                    <ResponsiveContainer width='100%' aspect={528/228}>
                        <LineChart
                            data={react.state.elements}
                        >
                            <XAxis dataKey="title" />
                            <Tooltip />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Line type="monotone" dataKey="count" stroke="#00798C" yAxisId={1} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        )

    }

}

export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(DashboardGraph);

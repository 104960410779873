import React, {Fragment} from 'react';
import LeftMenu from "../components/LeftMenu";
import {connect} from "react-redux";
import {getUsers} from "../actions/userActions";
import UserModal from "../components/UserModal";
import moment from "moment";
import {getTasks, getTasksExcel, getTasksRelations, getTasksTotal} from "../actions/taskActions";
import CreateTaskModal from "../components/CreateTaskModal";
import CustomSelect from "../components/CustomSelect";
import TaskModal from "../components/TaskModal";
import {Redirect} from "react-router";
import ImportModal from "../components/ImportModal";

class Tasks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initial_loading: true,
            loading: false,
            type: 'Act Of Kindness',
            act_of_kindness: 0,
            self_care: 0,
            relationship_maintenance: 0,
            tasks: [],

            create_task_modal_open: false,

            task_modal_open: false,
            task_modal_obj: null,

            import_modal_open: false,

            page: 1,
            per_page: 10,
            total: 20,

            important_person_relations: []


        }
    }

    componentWillMount() {
        this.loadData();
    }

    async loadData() {

        let react = this;
        react.setState({
            initial_loading: true
        })

        try {

            let response = await getTasks(react.props.access_token, react.state.type, react.state.page);
            let total_response = await getTasksTotal(react.props.access_token);
            let relations_response = await getTasksRelations(react.props.access_token);

            console.log(response);
            console.log(total_response);
            console.log(relations_response);

            react.setState({
                initial_loading: false,
                act_of_kindness: total_response.data.act_of_kindness,
                self_care: total_response.data.self_care,
                relationship_maintenance: total_response.data.relationship_maintenance,
                tasks: response.data,
                total: response.meta.total,
                per_page: response.meta.per_page,
                important_person_relations: relations_response.data
            })

        } catch (error) {
            console.log(error);
        }

    }

    async loadTasks() {

        let react = this;
        react.setState({
            loading: true
        })

        try {

            let response = await getTasks(react.props.access_token, react.state.type, react.state.page);
            console.log(response);

            react.setState({
                loading: false,
                tasks: response.data,
                total: response.meta.total,
                per_page: response.meta.per_page
            })

        } catch (error) {
            console.log(error);
        }

    }

    openImportModal() {
        this.setState({
            import_modal_open: true
        })
    }

    closeImportModal() {
        this.setState({
            import_modal_open: false
        })
    }

    openCreateTaskModal() {
        this.setState({
            create_task_modal_open: true
        })
    }

    closeCreateTaskModal() {
        this.setState({
            create_task_modal_open: false
        })
    }

    openTaskModal(obj) {
        this.setState({
            task_modal_open: true,
            task_modal_obj: obj
        })
    }

    closeTaskModal() {
        this.setState({
            task_modal_open: false,
            task_modal_obj: null
        })
    }

    async downloadExcel() {

        let react = this;

        let response = await getTasksExcel(react.props.access_token, react.state.type);

        console.log(response);

        const link = document.createElement('a');
        link.href = response.data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    render() {

        let react = this;
        if (react.props.access_token === null) {
            return <Redirect to={"/login"}/>
        }

        let end = false;
        let page_1 = 1;
        if (react.state.page > 1) {
            page_1 = react.state.per_page * (react.state.page - 1);
        }
        let page_2 = react.state.per_page * react.state.page;
        if (page_2 > react.state.total) {
            end = true;
            page_2 = react.state.total;
        }

        return (
            <Fragment>
                <ImportModal
                    open={react.state.import_modal_open}
                    onClose={() => {
                        react.closeImportModal();
                    }}
                    onUpdate={() => {
                        react.setState({
                            page: 1
                        }, () => {
                            react.closeImportModal();
                            react.loadData();
                        })
                    }}
                />
                <CreateTaskModal
                    open={react.state.create_task_modal_open}
                    onClose={react.closeCreateTaskModal.bind(react)}
                    onUpdate={(type) => {
                        react.setState({
                            type,
                            page: 1
                        }, () => {
                            react.closeCreateTaskModal();
                            react.loadData();
                        })
                    }}
                    important_person_relations={react.state.important_person_relations}
                />
                <TaskModal
                    open={react.state.task_modal_open}
                    task={react.state.task_modal_obj}
                    type_of_task={react.state.type}
                    onClose={react.closeTaskModal.bind(react)}
                    onUpdate={(type) => {
                        react.setState({
                            type,
                            page: 1
                        }, () => {
                            react.closeTaskModal();
                            react.loadData();
                        })
                    }}
                    important_person_relations={react.state.important_person_relations}
                />
                <LeftMenu/>
                <div className="page-content">
                    <div className="page-content-inner">
                        {react.state.initial_loading ? (
                            <div className="page-content-loader">
                                <div className="loader">
                                    <div className="inner one"/>
                                    <div className="inner two"/>
                                    <div className="inner three"/>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <div className="page-top row">
                                    <h1 className="page-top-title">Tasks Management</h1>
                                    <div className="page-top-right">
                                        <div className="num-block row">
                                            <div className="num-block-icon">
                                                <img src="/images/money.svg" alt="" className="num-block-icon-image"/>
                                            </div>
                                            <div className="num-block-right">
                                                <span className="num-block-label">Self Care</span>
                                                <span className="num-block-value">{react.state.self_care}</span>
                                            </div>
                                        </div>
                                        <div className="num-block row">
                                            <div className="num-block-icon">
                                                <img src="/images/money.svg" alt="" className="num-block-icon-image"/>
                                            </div>
                                            <div className="num-block-right">
                                                <span className="num-block-label">Act of kindness</span>
                                                <span className="num-block-value">{react.state.act_of_kindness}</span>
                                            </div>
                                        </div>
                                        <div className="num-block row">
                                            <div className="num-block-icon">
                                                <img src="/images/people.svg" alt="" className="num-block-icon-image"/>
                                            </div>
                                            <div className="num-block-right">
                                                <span className="num-block-label">Relationship Maintenance</span>
                                                <span className="num-block-value">{react.state.relationship_maintenance}</span>
                                            </div>
                                        </div>
                                        <button className="create-button" onClick={react.openCreateTaskModal.bind(react)}>
                                            <img className="create-button-icon" src="/images/icons/button-plus.svg" alt=""/>
                                            <span className="create-button-label">Create new</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="tasks-inner">
                                    <div className="tasks-inner-top">
                                        <div className="tasks-inner-top-select">
                                            <CustomSelect
                                                placeholder={"Type of Task"}
                                                value={react.state.type}
                                                elements={['Self Care', 'Act Of Kindness', 'Relationship Maintenance', 'Bonus Act Of Kindness']}
                                                onSelect={(element) => {
                                                    react.setState({
                                                        type: element,
                                                        page: 1,
                                                        per_page: 10,
                                                        total: 20,
                                                    }, () => {
                                                        react.loadTasks();
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="tasks-inner-top-buttons">
                                            <div className="tasks-inner-button" onClick={react.downloadExcel.bind(react)}>
                                                <img src="/images/icons/export.svg" alt="" className="tasks-inner-button-icon"/>
                                                <span className="tasks-inner-button-label">Download Excel</span>
                                            </div>
                                            <div className="tasks-inner-button" onClick={() => {
                                                react.openImportModal();
                                            }}>
                                                <img src="images/icons/import.svg" alt="" className="tasks-inner-button-icon"/>
                                                <span className="tasks-inner-button-label">Import Excel</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tasks-inner-elements row">
                                        {
                                            react.state.loading ? (
                                                <div className="tasks-inner-elements-loader">
                                                     <div className="spinner"/>
                                                </div>
                                            ) : (
                                                <Fragment>
                                                    {
                                                        react.state.tasks.map(function(task) {
                                                            return (
                                                                <div className="tasks-inner-element">
                                                                    <button className="tasks-inner-element-button" onClick={react.openTaskModal.bind(react, task)}>Edit</button>
                                                                    <span className="tasks-inner-element-title">{task.title}</span>
                                                                    <div className="tasks-inner-element-description">
                                                                        <p>{task.description}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="data-table-bottom">
                                    <div className="pagination row">
                                        {react.state.total > react.state.per_page && (
                                            <div className="pagination-left row">
                                                <span className="pagination-label">Showing {page_1} to {page_2} from {react.state.total} entries</span>
                                                <div className="pagination-area">
                                                    <button className="pagination-area-left" onClick={() => {
                                                       if (react.state.page !== 1) {
                                                           react.setState({
                                                               page: --react.state.page
                                                           }, () => {
                                                               react.loadTasks();
                                                           })
                                                       }
                                                    }}/>
                                                    <input type="text" disabled value={react.state.page} className="pagination-area-input"/>
                                                    <button className="pagination-area-right" onClick={() => {
                                                       if (!end) {
                                                           react.setState({
                                                               page: ++react.state.page
                                                           }, () => {
                                                               react.loadTasks();
                                                           })
                                                       }
                                                    }}/>
                                                </div>
                                            </div>
                                        )}
                                        <span className="pagination-copyright">&copy; {moment().format("YYYY")} A Little Dose of Happy</span>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        )

    }

}


export default connect(
    (state) => ({
        access_token: state.user.access_token,
    }),
    (dispatch) => ({}),
)(Tasks);

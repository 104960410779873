import axios from "axios";
import {api_url} from "../variables";
import Cookies from 'js-cookie'

export function getUsers(
    access_token,
    page
) {

    return new Promise((resolve, reject) => {

        axios.get(api_url + '/api/backend/user-management/users?page=' + page, {
            headers: {
                "Accept": "application/json",
                Authorization: 'Bearer ' + access_token,
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}

export function userSet(access_token) {

    Cookies.set('access_token', access_token, { path: '' })

    return {
        type: 'SET_USER',
        access_token: access_token,
    };

};


export function userLogout() {

    Cookies.remove('access_token', { path: '' })

    return {
        type: 'LOGOUT',
    };

};

export function userLogin(
  email,
  password
) {

    return new Promise((resolve, reject) => {

        axios.post(api_url + '/api/backend/auth/login', {
            email,
            password
        }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        })

    })

}
